export function log(...messages) {
  console.log("[rinsed][pwa]", ...messages);

  // keep logs in `window.rinsed_logs` also.
  // when testing on an iPhone it's hard to get all the logs, because you can't open the dev console on your mac until
  // after the app has already started, and you've missed some logs.
  try {
    window.rinsed_logs = window.rinsed_logs || [];
    window.rinsed_logs = window.rinsed_logs.slice(-100); // keep only the most recent logs
    window.rinsed_logs.push(messages);
  } catch (e) {
    console.error("Error in pwa_logger:", e);
  }
}
