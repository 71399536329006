import EventController from "../../event_controller";

export default class extends EventController {
  static values = {
    ...EventController.values,
    surveyLocationDropdownPresent: Boolean,
    rating: String,
  };

  static targets = ["form"];

  connect() {
    super.connect();

    // Add PostHog distinct_id to form on connect
    if (this.hasFormTarget && window.posthog) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = "survey_response[posthog_distinct_id]";
      hiddenField.value = posthog.get_distinct_id();
      this.formTarget.appendChild(hiddenField);
    }
  }

  eventProperties(baseProperties, _eventType, _node, _target) {
    return {
      ...baseProperties,
      survey_location_dropdown_present: this.surveyLocationDropdownPresentValue,
    };
  }
}
