import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { log } from "helpers/pwa_logger";
import { isPwa, isPwaInstalled, isPushSupported } from "helpers/pwa_utils";

export default class extends Controller {
  static values = {
    notificationTestPath: String,
    railsEnv: String,
    scope: String,
    subscriptionPath: String,
    vapidKeyBytes: Array,
  };
  static targets = ["subscribeBanner", "testBanner"];

  async connect() {
    log("push_subscription_controller connected");
    this.#showBanners();
  }

  async #showBanners() {
    let isRunningAsPwa = await isPwa();
    if (this.railsEnvValue === "test") {
      // TODO: Get rid of this once we have native-environment tests
      isRunningAsPwa = true;
    }

    if (isRunningAsPwa && isPushSupported()) {
      log("Showing notification banners");
      const subscription = await this.#subscription();
      if (subscription) {
        log("Already subscribed - showing test banner");
        this.testBannerTarget.classList.remove("hidden");
      } else {
        log("Not subscribed - showing subscribe banner");
        this.subscribeBannerTarget.classList.remove("hidden");
      }
    } else {
      log(
        `Not showing notification banners. isPwa=${isRunningAsPwa} isPushSupported=${isPushSupported()}`
      );
    }
  }

  async subscribe() {
    try {
      // Request permission to send notifications.
      // If permission has already been granted then this will return right away.
      const permission = await Notification.requestPermission();
      log("Permission returned:", permission);
      if (permission !== "granted") {
        throw new Error("Notification permission denied");
      }

      // Create a subscription via the brower's push service (e.g. with Apple, Google)
      // If the browser has already generated a subscription then this will return right away.
      const pushManager = (await this.#registration()).pushManager;
      this.#stubInTest(pushManager);
      const subscription = await pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: new Uint8Array(this.vapidKeyBytesValue),
      });
      log("Subscription:", subscription);

      // Send the subscription to the server
      const response = await post(this.subscriptionPathValue, {
        body: JSON.stringify({
          scope: this.scopeValue,
          subscription: subscription.toJSON(),
        }),
        responseKind: "turbo-stream",
      });
      await this.#validateResponse(response);

      // Show test banner
      this.testBannerTarget.classList.remove("hidden");
    } catch (error) {
      console.error("Failed to subscribe:", error);
      this.subscribeBannerTarget.textContent = "Failed to subscribe: " + error;
    }
  }

  async sendTestNotification() {
    try {
      const subscription = await this.#subscription();
      const response = await post(this.notificationTestPathValue, {
        body: { endpoint: subscription.endpoint },
      });
      await this.#validateResponse(response);
      log("Notification enqueued!");
    } catch (error) {
      alert("Notification failed: " + error);
    }
  }

  async #registration() {
    return await navigator.serviceWorker.ready;
  }

  async #subscription() {
    return await (await this.#registration()).pushManager.getSubscription();
  }

  async #validateResponse(response) {
    if (!response.ok) {
      const body = await response.text;
      throw new Error(`Request failed: ${response.statusCode} ${body.slice(0, 500)}`);
    }
  }

  #stubInTest(pushManager) {
    // Avoid actually creating subscriptions on Google's infra during specs
    if (this.railsEnvValue === "test") {
      pushManager.subscribe = async () => {
        return {
          toJSON: () => ({
            endpoint: "https://fake-push-endpoint.example.com",
            keys: {
              auth: "fake-auth",
              p256dh: "fake-p256dh",
            },
          }),
        };
      };
    }
  }
}
