import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["payment-update"];
  static values = { stripeApiKey: String, stripeAccountId: String };
  static targets = ["token", "cardElement", "stripeError"];

  connect() {
    this.paymentUpdateOutlet.addAsyncSubmitInterrupt(this.handleSubmit.bind(this));

    this.stripe = Stripe(
      this.stripeApiKeyValue,
      this.stripeAccountIdValue ? { stripeAccount: this.stripeAccountIdValue } : {}
    );
    this.cardElement = this.stripe
      .elements()
      .create("card", { style: this.style(), disableLink: true });

    this.cardElement.mount(this.cardElementTarget);
    this.cardElement.on("change", () => this.paymentUpdateOutlet.enableSubmit());
  }

  style() {
    return {
      base: {
        fontFamily:
          "Inter var,ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
        fontSize: "16px",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
  }

  async handleSubmit() {
    // We need to tokenize the credit card
    const token = await this.generatePaymentToken();

    if (token) {
      this.tokenTarget.value = token;
      return true;
    } else {
      return false;
    }
  }

  async generatePaymentToken() {
    const firstName = this.paymentUpdateOutlet.firstName() || "";
    const lastName = this.paymentUpdateOutlet.lastName() || "";

    try {
      const { error, token } = await this.stripe.createToken(this.cardElement, {
        name: `${firstName} ${lastName}`.trim(),
      });

      if (token) {
        this.clearErrorMessage();
        return token.id;
      }

      this.setErrorMessages(error?.message);
    } catch (error) {
      this.setErrorMessages(error?.message);
    }

    return null;
  }

  setErrorMessages(message) {
    this.stripeErrorTarget.textContent = message || "Something went wrong. Please try again.";
    this.stripeErrorTarget.hidden = false;
  }

  clearErrorMessage() {
    this.stripeErrorTarget.textContent = "";
    this.stripeErrorTarget.hidden = true;
  }
}
