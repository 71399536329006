import { Controller } from "@hotwired/stimulus";
import { formatPhoneNumber } from "../formatters";

export default class extends Controller {
  format(event) {
    const input = event.target.value;

    // format as a referral code if the user enters r- or R- followed by exactly 7 letters (besides l)
    if (input.match(/^[rR]-[a-km-zA-KM-Z]{7}$/)) {
      event.target.value = input
        .toLowerCase()
        .replace(/[^a-km-z-]/g, "")
        .substring(0, 9);
      return;
    }

    const digitsOnly = input.replace(/\D/g, "");

    // format as a phone number if the user enters only digits, spaces, dashes, or parentheses and there are exactly 10 digits
    if (input.startsWith("(") || (input.match(/^[\d\s\-\(\)]+$/) && digitsOnly.length > 0)) {
      if (digitsOnly.length >= 10) {
        const truncatedInput = input.replace(/\D/g, "").substring(0, 10);
        event.target.value = truncatedInput;
        formatPhoneNumber(event);
      } else {
        // if they open with a parenthesis, then only allow digits, spaces, dashes, and parentheses after
        event.target.value = input.replace(/[^\d\s\-\(\)]/g, "");
      }
      return;
    }

    event.target.value = input
      // format as an uppercase license plate if the user enters only letters, numbers, and dashes and none of the above criteria are met
      .replace(/[^0-9a-zA-Z\-]/g, "")
      .toUpperCase()
      .substring(0, 10);
  }
}
