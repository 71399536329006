import { log } from "helpers/pwa_logger";

/**
 * Checks if the application is running in standalone mode (installed as a PWA)
 * @returns {boolean} True if the app is currently running in PWA mode
 */
export function isPwa() {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  log("Display mode standalone:", isStandalone);
  return isStandalone;
}

/**
 * Checks if the PWA application is installed using getInstalledRelatedApps API
 * Only available on Android
 * @returns {Promise<boolean>} Resolves to true if the PWA is installed
 */
export async function isPwaInstalled() {
  // Only available on Android
  if (!isAndroid() || !("getInstalledRelatedApps" in navigator)) {
    log("getInstalledRelatedApps not supported");
    return false;
  }

  try {
    const relatedApps = await navigator.getInstalledRelatedApps();
    log("Related apps found:", relatedApps.length > 0, relatedApps);
    return relatedApps.length > 0;
  } catch (error) {
    log("Error checking for installed related apps:", error);
    return false;
  }
}

/**
 * Checks if the device is running Android
 * @returns {boolean} True if Android device
 */
export function isAndroid() {
  return /android/.test(navigator.userAgent.toLowerCase());
}

/**
 * Checks if the device is running iOS
 * @returns {boolean} True if iOS device
 */
export function isiOS() {
  return /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
}

/**
 * Checks if the browser is Firefox
 * @returns {boolean} True if Firefox browser
 */
export function isFirefox() {
  return /firefox/.test(navigator.userAgent.toLowerCase());
}

/**
 * Checks if push notifications are supported in current browser
 * @returns {boolean} True if push is supported
 */
export function isPushSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}
