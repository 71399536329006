import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["itemsContainer", "item"];

  addItem(event) {
    const template = document
      .getElementById("knowledge-base-item-template")
      .content.cloneNode(true);

    const newIndex = this.itemsContainerTarget.children.length - 1;
    const newFields = template.querySelectorAll("[name]");

    newFields.forEach((field) => {
      field.name = field.name.replace("[]", `[${newIndex}]`);
    });

    this.itemsContainerTarget.appendChild(template);
  }

  removeItem(event) {
    const item = event.target.closest(
      "[data-support-agent--knowledge-base-items-field-target='item']"
    );

    // If the item has an ID, mark it for destruction instead of removing it from the DOM immediately
    const hiddenDestroyField = item.querySelector("[name*='[_destroy]']");
    if (hiddenDestroyField) {
      hiddenDestroyField.value = "1";
      item.style.display = "none"; // Hide instead of removing immediately
    } else {
      item.remove();
    }
  }
}
