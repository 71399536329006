import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["agentContainer", "agentIndex", "agentForm"];

  static values = {
    locationsByLocationGroup: String,
  };
  connect() {
    this.locationsByLocationGroup = JSON.parse(this.locationsByLocationGroupValue);
  }

  addAgent() {
    const template = document
      .getElementById("support-agent-onboarding--setup-template")
      .content.cloneNode(true);

    this.agentContainerTarget.appendChild(template);
  }

  removeAgent(event) {
    const agentForm = event.target.closest(
      "[data-support-agent-onboarding--setup-target='agentForm']"
    );
    if (agentForm) {
      agentForm.remove();
    }
  }

  updateLocations(event) {
    const agentForm = event.target.closest(
      "[data-support-agent-onboarding--setup-target='agentForm']"
    );
    const locationSelect = agentForm.querySelector(
      "[data-support-agent-onboarding--setup-target='locationSelect']"
    );

    const locationGroupId = event.target.value;

    if (locationGroupId) {
      this.enableElement(locationSelect);
      const options = this.locationsByLocationGroup[locationGroupId]
        .map((location) => ({
          label: location.name,
          value: location.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      this.setDropdownOptions(locationSelect, options);
    } else {
      this.disableElement(locationSelect);
      this.setDropdownOptions(locationSelect, []);
    }
  }

  disableElement(element) {
    element.disabled = true;
  }

  enableElement(element) {
    element.disabled = false;
  }

  setDropdownOptions(select, options) {
    select.replaceChildren(
      ...options.map(({ label, value, selected }) => {
        const option = new Option(label, value);
        if (selected) option.selected = true;
        return option;
      })
    );
  }
}
