import { Controller } from "@hotwired/stimulus";
import { isPwa, isPwaInstalled, isAndroid, isiOS, isFirefox } from "helpers/pwa_utils";
import { log } from "helpers/pwa_logger";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "androidPostInstallBanner",
    "firefoxAndroidCloseButton",
    "firefoxAndroidOverlay",
    "firefoxAndroidPanel",
    "installBanner",
    "installButton",
    "iosCloseButton",
    "iosOverlay",
    "iosPanel",
    "openAppBanner",
  ];
  static values = {
    animationDuration: Number,
    isPwaSession: Boolean,
    isPwaOverride: Boolean,
  };

  async connect() {
    log("pwa_install_controller connected");

    // Do this immediately to stop the default prompt
    this.addBeforeInstallPromptListener();

    // Check for PWA without PWA session
    this.upgradePwaSession();

    if (await this.#isPwa()) {
      log("Running as PWA");
    } else {
      if (isiOS()) {
        log("Showing install banner for iOS");
        this.installBannerTarget.classList.remove("hidden");
      } else if (isAndroid()) {
        if (isFirefox()) {
          this.installBannerTarget.classList.remove("hidden");
        } else if (await isPwaInstalled()) {
          log("PWA installed. Showing open link.");
          this.openAppBannerTarget.classList.remove("hidden");
        } else {
          log("Waiting for beforeinstallprompt to fire");
        }
      }
    }
  }

  addBeforeInstallPromptListener() {
    log("Adding beforeinstallprompt listener");
    window.addEventListener("beforeinstallprompt", (e) => {
      log("Received beforeinstallprompt");
      if (isAndroid()) {
        log("Android detected. Showing install banner.");
        e.preventDefault();
        this.deferredPrompt = e;
        this.installBannerTarget.classList.remove("hidden");
      } else {
        log("Android not detected. Not showing install banner.");
      }
    });
  }

  // Main install action called from button click
  install() {
    log("Install button clicked");

    // Check if Android installation is possible
    if (isiOS()) {
      log("Showing iOS guide");
      this.#showiOSGuide();
    } else if (isAndroid()) {
      if (isFirefox()) {
        log("Android Firefox detected, showing Chrome redirect");
        this.#showFirefoxAndroidGuide();
      } else {
        log("Showing Android installation");
        this.#showAndroidInstall();
      }
    } else {
      log("Not on iOS or Android, not installing.");
    }
  }

  // Handle Android installation prompt
  async #showAndroidInstall() {
    if (!this.deferredPrompt) {
      log("No deferred prompt available");
      return;
    }

    try {
      log("Showing Android install prompt");
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      log("Waiting for user choice...");
      const choiceResult = await this.deferredPrompt.userChoice;

      log("User choice:", choiceResult.outcome);
      if (choiceResult.outcome === "accepted") {
        log("Installation accepted");
        this.installBannerTarget.classList.add("hidden");
        this.androidPostInstallBannerTarget.classList.remove("hidden");
        this.#checkForInstallation();
      } else {
        log("Installation declined");
      }
    } catch (error) {
      log("Install prompt error:", error);
    } finally {
      // Clear the saved prompt - it can only be used once.
      log("Clearing deferred prompt");
      this.deferredPrompt = null;
      setTimeout(() => {
        // We should immediately get another `beforeinstallprompt`, but if not, hide the install
        // banner until we do.
        if (!this.deferredPrompt) {
          log("No new deferredPrompt set, hiding install banner");
          this.installBannerTarget.classList.add("hidden");
        }
      }, 250);
    }
  }

  #showiOSGuide() {
    log("Showing iOS installation guide");
    this.iosOverlayTarget.classList.remove("pointer-events-none", "opacity-0");
    requestAnimationFrame(() => {
      // wait for the overlay to become un-hidden
      this.iosPanelTarget.classList.remove("translate-y-full");
    });
    setTimeout(() => {
      this.iosCloseButtonTarget.classList.remove("hidden");
    }, this.animationDurationValue);
  }

  closeiOSGuide() {
    log("Closing iOS installation guide");
    this.iosCloseButtonTarget.classList.add("hidden");
    this.iosPanelTarget.classList.add("translate-y-full");
    setTimeout(() => {
      this.iosOverlayTarget.classList.add("opacity-0", "pointer-events-none");
    }, this.animationDurationValue);
  }

  #showFirefoxAndroidGuide() {
    log("Showing Firefox Android guide");
    this.firefoxAndroidOverlayTarget.classList.remove("pointer-events-none", "opacity-0");
    requestAnimationFrame(() => {
      // wait for the overlay to become un-hidden
      this.firefoxAndroidPanelTarget.classList.remove("translate-y-full");
    });
    setTimeout(() => {
      this.firefoxAndroidCloseButtonTarget.classList.remove("hidden");
    }, this.animationDurationValue);
  }

  closeFirefoxAndroidGuide() {
    log("Closing Firefox Android guide");
    this.firefoxAndroidCloseButtonTarget.classList.add("hidden");
    this.firefoxAndroidPanelTarget.classList.add("translate-y-full");
    setTimeout(() => {
      this.firefoxAndroidOverlayTarget.classList.add("opacity-0", "pointer-events-none");
    }, this.animationDurationValue);
  }

  #checkForInstallation() {
    log("Starting installation check");
    // Check every second for a while to see if the PWA has been installed
    let checkCount = 0;
    const checkInterval = setInterval(async () => {
      checkCount++;
      log(`Check ${checkCount} for installation...`);

      if (await isPwaInstalled()) {
        log("PWA installation detected");
        clearInterval(checkInterval);
        setTimeout(async () => {
          // Wait for a few seconds before attempting to open. Seems to not work otherwise.
          this.androidPostInstallBannerTarget.classList.add("hidden");
          this.openAppBannerTarget.classList.remove("hidden");
        }, 6000);
      } else if (checkCount >= 30) {
        log("Installation check timed out");
        clearInterval(checkInterval);
      }
    }, 1000);
  }

  // If we're running in a PWA context but don't have a "pwa" session, then upgrade the current
  // session to a PWA session
  async upgradePwaSession() {
    if ((await this.#isPwa()) && !this.isPwaSessionValue) {
      log("Detected PWA mode without a PWA session. Upgrading session...");

      try {
        // Call the endpoint to upgrade the session
        const response = await post("/portal/pwa_session_upgrades", {
          responseKind: "json",
        });

        if (response.ok) {
          log("Successfully upgraded to PWA session");
          // the cookie is now set via the server side response
        } else {
          log("Failed to upgrade to PWA session:", response.statusText);
        }
      } catch (error) {
        log("Error upgrading to PWA session:", error);
      }
    }
  }

  // Wrapper for `isPwa` to allow changing the value in the test env
  async #isPwa() {
    let isPwaMode = await isPwa(); // Always run the standard detection
    if (this.isPwaOverrideValue) {
      return true;
    } else {
      return isPwaMode;
    }
  }
}
