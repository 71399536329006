import EventController from "../../event_controller";

export default class extends EventController {
  static values = {
    ...EventController.values,
    surveyGoogleReviewUrl: String,
    rating: String,
  };

  eventProperties(baseProperties, _eventType, _node, _target) {
    return {
      ...baseProperties,
      survey_google_review_url: this.surveyGoogleReviewUrlValue,
      survey_rating: this.ratingValue,
    };
  }
}
