import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "licensePlateShown",
    "licensePlateRequired",
    "birthdayShown",
    "birthdayRequired",
  ];

  connect() {
    this.licensePlateShownChange();
    this.birthdayShownChange();
  }

  licensePlateShownChange(event) {
    if (this.licensePlateShownTarget.checked) {
      this.licensePlateRequiredTarget.disabled = false;
    } else {
      this.licensePlateRequiredTarget.disabled = true;
      this.licensePlateRequiredTarget.checked = false;
    }

    if (event) {
      this.element.requestSubmit();
    }
  }

  birthdayShownChange(event) {
    if (this.birthdayShownTarget.checked) {
      this.birthdayRequiredTarget.disabled = false;
    } else {
      this.birthdayRequiredTarget.disabled = true;
      this.birthdayRequiredTarget.checked = false;
    }

    if (event) {
      this.element.requestSubmit();
    }
  }
}
