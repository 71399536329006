import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.resultsContainer = document.getElementById("autocomplete-results");
    this.debounceTimer = null;
    this.lastQuery = "";
    this.selectedIndex = -1;

    this.element.addEventListener("keydown", this.handleKeyDown.bind(this));

    // Auto-select text on focus
    this.element.addEventListener("focus", () => {
      setTimeout(() => this.element.select(), 100);
    });
  }

  search(event) {
    const query = event.target.value.trim();

    if (query.length < 2) {
      this.hideResults();
      return;
    }

    this.lastQuery = query;

    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(async () => {
      if (this.lastQuery === query) {
        await this.fetchResults(query);
      }
    }, 100);
  }

  async fetchResults(query) {
    try {
      const response = await fetch(
        `/checkout_forms/autocomplete?query=${encodeURIComponent(query)}`
      );
      const data = await response.json();
      this.displayResults(data);
    } catch (error) {
      // ignore the error
    }
  }

  displayResults(results) {
    if (results.length === 0) {
      this.hideResults();
      return;
    }

    this.results = results;
    this.resultsContainer.innerHTML = "";

    results.forEach((result, index) => {
      const item = document.createElement("div");
      item.className =
        "p-3 hover:bg-gray-100 cursor-pointer text-sm border-b border-gray-200 last:border-b-0";
      item.dataset.index = index;

      // Use structured formatting if available for better display
      if (result.structured_formatting) {
        const mainText = document.createElement("div");
        mainText.className = "font-medium";
        mainText.textContent = result.structured_formatting.main_text;

        const secondaryText = document.createElement("div");
        secondaryText.className = "text-gray-500 text-xs mt-0.5";
        secondaryText.textContent = result.structured_formatting.secondary_text;

        item.appendChild(mainText);
        item.appendChild(secondaryText);
      } else {
        item.textContent = result.description;
      }

      item.addEventListener("click", () => {
        this.selectItem(index);
      });

      item.addEventListener("mouseenter", () => {
        this.highlightItem(index);
      });

      this.resultsContainer.appendChild(item);
    });

    this.resultsContainer.classList.remove("hidden");
    this.selectedIndex = -1;
  }

  handleKeyDown(event) {
    if (!this.isResultsVisible()) {
      return;
    }

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        this.selectNextItem();
        break;
      case "ArrowUp":
        event.preventDefault();
        this.selectPreviousItem();
        break;
      case "Enter":
        event.preventDefault();
        if (this.selectedIndex >= 0) {
          this.selectItem(this.selectedIndex);
        }
        break;
      case "Escape":
        this.hideResults();
        break;
    }
  }

  selectNextItem() {
    const newIndex = this.selectedIndex < this.results.length - 1 ? this.selectedIndex + 1 : 0;
    this.highlightItem(newIndex);
  }

  selectPreviousItem() {
    const newIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.results.length - 1;
    this.highlightItem(newIndex);
  }

  highlightItem(index) {
    // Remove highlight from current selection
    const items = this.resultsContainer.querySelectorAll("div");
    items.forEach((item) => {
      item.classList.remove("bg-gray-100", "bg-blue-50", "text-blue-600");
    });

    // Add highlight to new selection
    if (index >= 0 && index < items.length) {
      items[index].classList.add("bg-blue-50", "text-blue-600");
      items[index].scrollIntoView({ block: "nearest" });
      this.selectedIndex = index;
    }
  }

  selectItem(index) {
    if (index >= 0 && index < this.results.length) {
      this.element.value = this.results[index].description;
      this.hideResults();
      this.element.form.submit();
    }
  }

  async onFocus() {
    // If there's text and no results showing, search again
    const query = this.element.value.trim();
    if (query.length >= 2 && !this.isResultsVisible()) {
      await this.fetchResults(query);
    }
  }

  onBlur(event) {
    // Small delay to allow click events on the results to fire
    setTimeout(() => {
      if (!this.resultsContainer.contains(document.activeElement)) {
        this.hideResults();
      }
    }, 150);
  }

  isResultsVisible() {
    return !this.resultsContainer.classList.contains("hidden");
  }

  hideResults() {
    this.resultsContainer.classList.add("hidden");
    this.selectedIndex = -1;
  }
}
