__webpack_nonce__ = window.nonce || "";

import "core-js/stable";
import "element-remove";
import "es7-object-polyfill";
import "intersection-observer";
import "nodelist-foreach-polyfill";
import "polyfill-array-includes";
import "promise-polyfill/src/polyfill";
import "regenerator-runtime/runtime";
import "url-search-params-polyfill";
import "whatwg-fetch";

import elementClosest from "element-closest";

elementClosest(window);
