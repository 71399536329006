import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: "sortable-ghost",
      chosenClass: "sortable-chosen",
      dragClass: "sortable-drag",
      onEnd: this.end.bind(this),
      handle: ".handle",
    });
  }

  end(_event) {
    const ids = this.sortable.toArray();
    this.sendOrderToServer(ids);
  }

  sendOrderToServer(ids) {
    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ resource_ids: ids }),
    }).then((response) => {
      if (!response.ok) {
        alert("Failed to update order.");
      }
    });
  }
}
