import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["qaContainer", "uploadErrors", "uploadSuccess", "processing"];

  addQuestion(event) {
    const template = document.getElementById("knowledge-question-template").content.cloneNode(true);

    this.qaContainerTarget.appendChild(template);
  }

  removeQuestion(event) {
    const question = event.target.closest(
      "[data-support-agent-onboarding--knowledge-target='questionForm']"
    );
    if (question) {
      question.remove();
    }
  }

  uploadErrorsTargetConnected(target) {
    this.processingTarget.hidden = true;
    this.submitButton.disabled = false;
  }

  uploadSuccessTargetConnected(target) {
    window.location.href = "/admin/support_agent/onboarding?step=done";
  }
}
