import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    apiKey: String,
  };
  static targets = ["overlay", "map", "spinner"];

  locate() {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by this browser");
      return;
    }

    this.overlayTarget.classList.remove("hidden");
    this.spinnerTarget.classList.remove("hidden");
    this.mapTarget.classList.add("hidden");

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude: lat, longitude: lon } = position.coords;
        const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${this.apiKeyValue}&q=${lat},${lon}&center=${lat},${lon}&zoom=17`;

        this.mapTarget.src = mapUrl;
        this.spinnerTarget.classList.add("hidden");
        this.mapTarget.classList.remove("hidden");
      },
      (error) => {
        alert(`Error fetching location: ${error.message}`);
        this.closeOverlay();
      }
    );
  }

  closeOverlay() {
    this.overlayTarget.classList.add("hidden");
    this.spinnerTarget.classList.add("hidden");
    this.mapTarget.classList.add("hidden");
  }
}
