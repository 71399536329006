import { Controller } from "@hotwired/stimulus";

const ACTIVE_CLASS = "active";
const HIDDEN_CLASS = "hidden";
const ROTATION_CLASS = "rotate-180";

export default class extends Controller {
  static targets = ["section", "header"];

  // In Tailwind mode, we want to toggle the hidden class instead of the active class.
  static values = {
    useTailwind: { type: Boolean, default: false },
  };

  connect() {
    this._boundClickHandler = this.toggleCollapsibleContent.bind(this);
    this.headerTarget.addEventListener("click", this._boundClickHandler);
    this.initializeIconState();
  }

  disconnect() {
    document.removeEventListener("click", this._boundClickHandler);
  }

  toggleCollapsibleContent() {
    if (this.useTailwindValue) {
      this.toggleTailwindContent();
    } else {
      this.toggleDefaultContent();
    }
  }

  initializeIconState() {
    if (this.useTailwindValue && this.isExpanded()) {
      const icon = this.getRotatableIcon();
      this.rotateIcon(icon, true);
    }
  }

  isExpanded() {
    return this.useTailwindValue
      ? !this.sectionTarget.classList.contains(HIDDEN_CLASS)
      : this.sectionTarget.classList.contains(ACTIVE_CLASS);
  }

  toggleTailwindContent() {
    const isCurrentlyHidden = this.sectionTarget.classList.contains(HIDDEN_CLASS);
    const icon = this.getRotatableIcon();

    if (isCurrentlyHidden) {
      this.expandSection();
      this.rotateIcon(icon, true);
    } else {
      this.collapseSection();
      this.rotateIcon(icon, false);
    }
  }

  toggleDefaultContent() {
    if (this.sectionTarget.classList.contains(ACTIVE_CLASS)) {
      this.sectionTarget.classList.remove(ACTIVE_CLASS);
    } else {
      this.sectionTarget.classList.add(ACTIVE_CLASS);
    }
  }

  expandSection() {
    this.sectionTarget.classList.remove(HIDDEN_CLASS);
  }

  collapseSection() {
    this.sectionTarget.classList.add(HIDDEN_CLASS);
  }

  getRotatableIcon() {
    return this.element.querySelector("[data-rotate]");
  }

  rotateIcon(icon, shouldRotate) {
    if (!icon) return;

    if (shouldRotate) {
      icon.classList.add(ROTATION_CLASS);
    } else {
      icon.classList.remove(ROTATION_CLASS);
    }
  }
}
