import EventController from "../event_controller";

export default class extends EventController {
  static values = {
    ...EventController.values,
    rinsedMembershipId: Number,
    rinsedPwaSession: Boolean,
  };

  eventProperties(baseProperties, _eventType, node, _target) {
    return {
      ...baseProperties,
      rinsed_membership_id: this.rinsedMembershipIdValue,
      rinsed_pwa_session: this.rinsedPwaSessionValue,
      ...this.#nodeProperties(node),
    };
  }

  #nodeProperties(node) {
    const { ticketId, offerId } = node.dataset;

    const properties = {};

    if (ticketId) {
      properties.ticket_id = parseInt(ticketId);
    }

    if (offerId) {
      properties.offer_id = parseInt(offerId);
    }

    return properties;
  }
}
